// If you want to override variables do it here
@import "variables";

// Import CoreUI styles
@import "~@coreui/coreui/scss/coreui.scss";

// If you want to add something do it here
@import "custom";

.detalle__delete {
    align-items: center;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    transition: color .3s ease;

    &:hover {
        color: darken($color: rgb(187, 183, 183), $amount: 20);
    }
}
