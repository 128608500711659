// Variable overrides
$primary: #054477;

$sidebar-dark-bg: $primary !default;
$sidebar-dark-header-bg:  rgba($primary, .2) !default;
$sidebar-dark-nav-link-hover-color: #2276bb;
$sidebar-dark-nav-link-hover-bg:  theme-color("primary") !default;
$sidebar-dark-nav-link-hover-icon-color:  white !default;

$sidebar-nav-icon-width: 56px !default;
$sidebar-light-nav-link-active-bg: rgba(#000015, .0) !default;
$sidebar-dark-nav-link-active-bg: rgba(#000015, .0) !default;