/* Modal */
.ReactModalPortal > div{
    opacity: 0;
}

.ReactModalPortal .ReactModal__Overlay {
    align-items: center;
    display: flex;
    justify-content: center;
    transition: opacity .2s ease-in-out;
    z-index: 999;
}

.modal-fondo {
    background-color: rgba(0, 0, 0, 0.3);
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    position: fixed;
}

.ReactModalPortal .ReactModal__Overlay--after-open {
    opacity: 1;
    transition: opacity .2s ease-in-out;
}

.ReactModalPortal .ReactModal__Overlay--before-close {
    opacity: 0;
    transition: opacity .2s ease-in-out;
}

.logo-home {
    margin:auto;
	text-align:center;
}

/* Required */
input[required] + label:after {
	content: '*';
	color: red;
  }

